export const initialState = {
  drawerVisibility: true,
  isShowMenu: false,
  tabIndex: 0,
  userInfo: {
    first_name: "",
    last_name: "",
    point_cloud_density: 70,
    enable_annotation_popup: 1,
    measurement_snap_on: 1,
    measurement_units: "imperial",
    label_property: "default",
    pole_icon: 0,
    style_color: "#929292",
    profile_name: "profile1",
    profile1_classification: "",
    profile2_classification: "",
    profile3_classification: "",
    annotation_type_list: "",
		annotation_status_list: "",
    role: "viewer"
  },
};

export const drawerVisibility = (state = initialState) => (
  state.drawerVisibility || initialState.drawerVisibility
);

export const projectTabIndex = (state = initialState) => (
  state.tabIndex || initialState.tabIndex
);