import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import { CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import './style.scss';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        overflow: "auto",
        width: "100%"
	},
    button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
    info_section: {
        position: 'absolute',
        marginTop: '20px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 110px)',
    },
    panel_label : {
        color: "#2196f3",
        fontSize: 20,
        marginTop: 3,
        textAlign: 'left',
    },
    project_label: {
        color: "#9e9e9e",
        fontSize: 16,
        marginTop: 3,
        textAlign: 'left',
        overflow: 'hidden',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    circular_progress: {
        width: 40,
        height: 40,
        position: 'absolute',
    }
}));

function DatasetPanel(props) {
	const classes = useStyles();

    function redirectToLogin() {
        props.history.replace("/");
    }

    const [progress, setProgress] = React.useState(false);

    const [tableRows, setTableRows] = React.useState([]);

    const [datasetSearch, setDatasetSearch] = React.useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const [updatingDataset, setUpdatingDataset] = React.useState(null);
    const [updatingDatasetName, setUpdatingDatasetName] = React.useState("");
    const [updatingDatasetDescription, setUpdatingDatasetDesc] = React.useState("");
    const [updatingDatasetSupplier, setUpdatingDatasetSupplier] = React.useState("");

    useEffect(() => {
		generateDatasetGrid(props.allDatasets);
	}, [props.allDatasets, props.selectedProject, props.allProjects]);

    useEffect(() => {
        if (window.datasetSearchTimer) {
            clearTimeout(window.datasetSearchTimer);
        }

        window.datasetSearchTimer = setTimeout(function() {
            if (props.allDatasets && props.allDatasets.length > 0) {
                generateDatasetGrid(props.allDatasets);
            }
        }, 300);
		
	}, [datasetSearch]);

    function onChangeUpdatingDatasetName(evt) {
        setUpdatingDatasetName(evt.target.value);
    }

    function onChangeUpdatingDatasetDescription(evt) {
        setUpdatingDatasetDesc(evt.target.value);
    }

    function onChangeUpdatingDatasetSupplier(evt) {
        setUpdatingDatasetSupplier(evt.target.value);
    }

    function onChangeDatasetSearch(evt) {
        setDatasetSearch(evt.target.value);
    }

    function generateDatasetGrid(datasets) {
        let rows = [];
        let addedDatasets = [];
        if (props.selectedProject.project && props.selectedProject.project.dataset_ids) {
            addedDatasets = JSON.parse(props.selectedProject.project.dataset_ids);
        }

        for (let i = 0; i < datasets.length; i ++) {
            if (datasets[i].is_report) continue;
            if (datasetSearch && datasetSearch !== "" && datasets[i].name.toLowerCase().indexOf(datasetSearch.toLowerCase()) < 0) continue;
            let type = (datasets[i].datatype === "")? "Not Set":
                (datasets[i].datatype === "pointcloud")?"3D Point Cloud":
                (datasets[i].datatype === "tiff")?"Orthomosaic":
                (datasets[i].datatype === "dem")?"DEM":
                (datasets[i].datatype === "kml")?"KML":
                (datasets[i].datatype === "img")?"Image":
                datasets[i].datatype;
            rows.push({
                id : datasets[i].id,
                name: datasets[i].name,
                capture_date : new Date(datasets[i].capture_date).toISOString().split('T')[0],
                update_date : datasets[i].created_at.substring(0, 16),
                type : type,
                status : datasets[i].id,
                isAdded : (addedDatasets.indexOf("" + datasets[i].id) >= 0)?true:false,
                isAddedInProject: (props.allProjects && props.allProjects.filter(function(value) {
                    return value.dataset_ids && JSON.parse(value.dataset_ids).indexOf("" + datasets[i].id) >= 0;
                }).length > 0)?true:false
            });
        }
        setTableRows(rows);
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: 220, sortable: true, headerClassName: 'datataset_table_class'},
        { field: 'type', headerName: 'Data Type', width: 150, sortable: true, headerClassName: 'datataset_table_class'},
        { field: 'capture_date', headerName: 'Capture Date', width: 150, sortable: true, headerClassName: 'datataset_table_class' },
        { field: 'update_date', headerName: 'Upload Date', width: 150, sortable: true, headerClassName: 'datataset_table_class' },
        { field: 'status', headerName: 'Action', width: 120, sortable: false, headerClassName: 'datataset_table_class',
          renderCell: (cellValue) => {
            return (
                <div className="info_status">
                    {cellValue.row.isAdded ? 
                    <div className="check_icon table_icon" title="Already added in current project">
                        <div className='icon'></div>
                    </div>: 
                    cellValue.row.isAddedInProject ?
                    <div className="add_green_icon table_icon" title="Added in other project" onClick={addDatasetToProject}>
                        <div className='icon hover_icon' data-id={cellValue.row.id}></div>
                    </div>
                    :
                    <div className="add_icon table_icon" onClick={addDatasetToProject} title="Not added">
                        <div className='icon hover_icon' data-id={cellValue.row.id}></div>
                    </div>
                    }
                    {api.canEditItem(props.userInfo)?
                    <div className="table_icon" onClick={handleShowMenu}>
                        <div className='icon popup_menu_icon hover_icon' data-id={cellValue.row.id}></div>
                    </div>
                    :null}
                </div>
            );
          }},
    ];

    function getRenderMenuItem() {
        let renderMenu;
        const menuId = 'primary-action-menu';
        renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                className="sidebar-actionmenu-style"
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={editDataset}><div className='edit_menu_icon'></div>Edit</MenuItem>
                <MenuItem onClick={reprocessDataset}><div className='recreate_icon'></div>Reprocess</MenuItem>
                {api.canEditItem(props.userInfo)?
				<MenuItem onClick={downloadDataset}><div className='download_menu_icon'></div>Download</MenuItem>
				:null}
                <MenuItem onClick={deleteDataset}><div className='delete_menu_icon'></div>Delete</MenuItem>
                
            </Menu>
        );

        return renderMenu;
    }

    function getGridClassName(param) {
        return "datataset_table_class";
    }

    function hideProjectDialog() {
        setUpdatingDataset(null);
    }

    async function updateDatasetDialog() {
        let dataset = updatingDataset;
        hideProjectDialog();
        if (!updatingDatasetName || updatingDatasetName === "") {
            toast("Name shouldn't be empty.");
            return;
        }
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            setProgress(true);
            let response = await api.updateDatasetInfo({
                token : userToken, name : updatingDatasetName, id : dataset.id, description : updatingDatasetDescription?updatingDatasetDescription:"", supplier_name : updatingDatasetSupplier?updatingDatasetSupplier:""
            });

            if (response.data && !response.data.error) {
                refreshDataset();
            }
            else if (response.data) {
                toast(response.data.error)
            }

            setProgress(false);
        }
        else {
            redirectToLogin();
        }
    }

    async function addToProject(datasetID) {
        if (!datasetID) return;
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            setProgress(true);
            let response = await api.addDataset({token: userToken, id: props.selectedProject.project.id, dataset_id: datasetID + ""});

            if (response.data && !response.data.error) {
                props.selectProject(response.data);
            }

            setProgress(false);
        }
        else {
            redirectToLogin();
        }
    }

    function addDatasetToProject(event) {
        if (props.userInfo.role === "viewer") {
            toast("Viewer can't add dataset to project.");
        }
        else if (props.selectedProject.project.id >= 0) {
            addToProject(event.target.dataset.id);
        }
        else {
            toast("Please select project");
        }
    }

    function handleShowMenu(event) {
        if (event.target && event.target.dataset.id) {
            window.selectedMenuFile = event.target.dataset.id;
        }
        else {
            window.selectedMenuFile = null;
        }
        if (event.target)
		    setAnchorEl(event.target);
	}

	function handleMenuClose() {
		setAnchorEl(null);
        setTimeout(function() {
            window.selectedMenuFile = null;
        }, 500);
	}

    function reprocessDataset() {
        let selectedDatasetID = window.selectedMenuFile;
        handleMenuClose();
        if (selectedDatasetID !== null) {
            let selectedDataset = props.allDatasets.filter(function(value) {
                return value.id + "" === selectedDatasetID + "";
            });
            if (selectedDataset.length > 0 && selectedDataset[0].status === "finish") {
                props.onDeleteDialog("Dataset", "Do you want to reprocess dataset " + selectedDataset[0].name + "?", function() {
                    reProcessDatasetByAPI(selectedDatasetID);
                }, "Start");
            }
            else {
                toast("Selected Dataset is processing yet.");
            }
        }
    }

    async function reProcessDatasetByAPI(dataset_id) {
        let userToken = localStorage.getItem("userToken");
        setProgress(true);
        if (userToken) {
            let response = await api.startProcessDataset({
                token : userToken, id : dataset_id, attach: false
            });
            
            if (response.data && !response.data.error) {
                refreshDataset();
            }
            setProgress(false);
        }
        else {
            toast("Failed. Please reload and try again.");
        }
    }

    function editDataset() {
        let selectedDatasetID = window.selectedMenuFile;
        handleMenuClose();
        if (selectedDatasetID !== null) {
            window.open("/upload?dataset_id=" + selectedDatasetID);
            // This is updating Dialog For Dataset
            // let selectedDataset = props.allDatasets.filter(function(value) {
            //     return value.id + "" === selectedDatasetID + "";
            // });
            
            // if (selectedDataset.length > 0) {
            //     console.log(selectedDataset[0]);
            //     setUpdatingDataset(selectedDataset[0]);
            //     setUpdatingDatasetName(selectedDataset[0].name);
            //     setUpdatingDatasetDesc(selectedDataset[0].description?selectedDataset[0].description:"");
            //     setUpdatingDatasetSupplier(selectedDataset[0].supplier_name?selectedDataset[0].supplier_name:"");
            // }
            // else {
            //     toast("Please select dataset.");
            // }
        }
    }

    function downloadDataset() {
        let selectedDatasetID = window.selectedMenuFile;
        handleMenuClose();
        if (selectedDatasetID !== null) {
            window.open("/download?dataset_id=" + selectedDatasetID);
        }
    }

    function deleteDataset() {
        let deletedDataset = window.selectedMenuFile;
        window.selectedMenuFile = null;
        handleMenuClose();
        if (deletedDataset !== null) {
            props.onDeleteDialog("Delete Dataset", "Do you want to delete this dataset completely?", function() {
                deleteDatasetAPI(deletedDataset);
            })
        }
    }

    async function deleteDatasetAPI(datasetID) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            setProgress(true);
            let response = await api.deleteDatasetByID({token: userToken, id: datasetID});

            if (response.data && !response.data.error) {
                if (response.data.fail_message) {
                    toast(response.data.fail_message);
                }
                else {
                    props.setAllDatasets(response.data);
                    getAllProjects();
                }
            }

            setProgress(false);
        }
        else {
            redirectToLogin();
        }
    }

    async function getAllProjects() {
		let userToken = localStorage.getItem("userToken");
        setProgress(true);
        if (userToken) {
            let response = await api.getProjects({token: userToken});

            if (response.data && !response.data.error) {
                props.setAllProjects(response.data);
                setProgress(false);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

    async function refreshDataset() {
		let userToken = localStorage.getItem("userToken");
        setProgress(true);
        if (userToken) {
            let response = await api.getDatasetList({token: userToken});

            if (response.data && !response.data.error) {
                props.setAllDatasets(response.data);
                setProgress(false);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	return (
		<div className={classes.root}>
            <div className={classes.panel_label}>
                <div className='dataset_label'>
                    <p className='dataset_title'>Datasets</p>
                    <div className={classes.project_label}>Current Project : { props.selectedProject.project.id !== -1 ?props.selectedProject.project.name:""}</div>
                </div>
                <div className='dataset_filter'>
                    <div className="can_hover_icon dataset_refresh no_boder_line" ><div className='icon refresh_icon' onClick={refreshDataset}></div></div>
                    <div className='dataset-search-name-box'>
                        <div className='search_icon'></div>
                        <input type="text" className="search-name-input" 
                        value={datasetSearch} 
                        onChange={onChangeDatasetSearch}></input>
                    </div>
                </div>
            </div>
            
            <div className={classes.info_section}>
                {progress? 
                    <CircularProgress className={classes.circular_progress}/>
                : null}
                <DataGrid
                    rows={tableRows}
                    columns={columns}
                    pageSize={30}
                    rowsPerPageOptions={[30]}
                    getCellClassName = {getGridClassName}
                    hideFooter={false}
                    disableSelectionOnClick
                    disableMultipleSelection
                    initialState={{
                        sorting: {
                          sortModel: [{ field: 'update_date', sort: 'desc' }],
                        },
                    }}
                    sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: '#363739',
                        color: "#797d87",
                        '& .MuiDataGrid-cell:hover': {
                            color: 'white',
                            cursor: 'pointer'
                        },
                        '& .MuiTablePagination-root': {
                            color: 'white',
                        },
                        "& .MuiPaginationItem-root": {
                            color: "#fff"
                        },
                        "& .MuiTablePagination-actions": {
                            button : {
                                color: "#fff",
                            },
                            "& .Mui-disabled": {
                                color: "#999"
                            }
                        },
                        "& .MuiDataGrid-iconButtonContainer": {
                            button : {
                                color: "#fff",
                            },
                            "& .Mui-disabled": {
                                color: "#999"
                            }
                        },
                        "& .MuiDataGrid-menuIcon" : {
                            display: "none"
                        },
                        "& .MuiDataGrid-overlay": {
                            background: "#17181b"
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                    />
            </div>

            <Modal show={updatingDataset !== null} animation={false}>
                <Modal.Header>
                    <Modal.Title>Update Dataset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='project-name-box'>
                        <p className="project-name-title">Name</p>
                        <input type="text" className="project-name-input" 
                        value={updatingDatasetName} 
                        onChange={onChangeUpdatingDatasetName}></input>
                    </div>
                    <div className='project-name-box'>
                        <p className="project-name-title">Description</p>
                        <textarea className="project-name-input" 
                        value={updatingDatasetDescription} 
                        onChange={onChangeUpdatingDatasetDescription}></textarea>
                    </div>
                    <div className='project-name-box'>
                        <p className="project-name-title">Supplier</p>
                        <input type="text" className="project-name-input" 
                        value={updatingDatasetSupplier} 
                        onChange={onChangeUpdatingDatasetSupplier}></input>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideProjectDialog} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={updateDatasetDialog} className={classes.button}>
                    Update
                </Button>
                </Modal.Footer>
            </Modal>

            {getRenderMenuItem()}
		</div>
	);
}

const mapStateToProps = state => ({
	allDatasets : state.project.allDatasets,
	allProjects : state.project.allProjects,
	selectedProject : state.project.selectedProject,
    userInfo : state.global.userInfo,
});

DatasetPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(DatasetPanel);
