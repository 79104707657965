import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { globalAction, projectAction } from '../../store/actions';
import './style.scss';
import {Modal} from 'react-bootstrap';
import Profile from '../Profile';
import UserManagement from '../UserManagement';
import { promisify } from '../../utils/promisify';
import { authActionCreators } from '../../store/actions';
import Button from '@material-ui/core/Button';
import * as api from '../../utils/api';

import 'react-toastify/dist/ReactToastify.css';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	hidden: {
		display: "none",
	},
	root: {
		flexGrow: 1,
		position: "fixed",
		zIndex: 1400,
		left: 0,
		right: 0,
		top: 0,
		backgroundColor: `#2964E3`,
	},
	menuButton: {
		marginLeft: 3,
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	searchIcon: {
		height: '100%',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
		border: '1px solid #3c4043',
		paddingLeft: 25,
		paddingRight: 25,
		borderRadius: 30
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	logo: {
		width: drawerWidth,
		height: 64,
		backgroundColor: `#17181b`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		cursor: "pointer"
	},
	back: {
		width: 64,
		height: 64,
		backgroundColor: `#17181b`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		cursor: "pointer"
	},
	appBar: {
		backgroundColor: `#17181b`,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	toggleImage: {
		width: 36,
		justifyContent: 'left',
		alignItems: 'left',
		marginRight: 15,
		backgroundColor: `#3C78FA`,
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	noPadding: {
		paddingLeft: 0,
	},
	defaultPadding: {
		paddingLeft: 24,
	},
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
}));

function Header(props) {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);

	const [isShowingUserManage, setShowingUserManage] = React.useState(false);

	const [isDelete, setDeleteDialog] = React.useState(false);
	const [deleteTitle, setDeleteTitle] = React.useState("");
	const [deleteMessage, setDeleteMessage] = React.useState("");
	const [deleteButtonName, setDeleteButtonName] = React.useState("Delete");

	useEffect(() => {
		if (props.hasSearch) {
			let search_element = document.getElementsByClassName("cesium-viewer-toolbar");
			let boxElement = document.getElementById("search-box");
			if (search_element && boxElement && search_element.length > 0) {
				boxElement.appendChild(search_element[0]);
			}
		}
		if (props.noAccount) {
			return;
		}

		if (api.isSharing()) {
			// Sharing
		}
		else {
			let userToken = localStorage.getItem("userToken");
			if (userToken) {
				promisify(props.getUser, {token: userToken})
					.then((res) => {
						props.setUserInfo(res);
					})
					.catch((err) => {
						redirects("/");
					})
			}
			else {
				redirects("/");
			}
		}
		
		return () => {
			if (props.hasSearch) {
				let search_element = document.getElementsByClassName("cesium-viewer-toolbar");
				let cesiumContainer = document.getElementsByClassName("cesium-viewer");
				if (search_element && cesiumContainer && search_element.length > 0 && cesiumContainer.length > 0) {
					cesiumContainer[0].appendChild(search_element[0]);
				}
			}
		}
	}, []);

	useEffect(() => {
		const onKeyEvent = (event) => {
			if (event.key === "Escape") {
				if (isDelete) {
					hideDeleteDialog();
				}
				else if (isShowingUserManage) {
					hideUserManage();
				}
			}
			else if (event.key === "Enter") {
				if (isDelete) {
					deleteRun();
				}
				else if (isShowingUserManage) {
				}
			}
		};

		window.addEventListener("keydown", onKeyEvent);
		
		return () => {
			window.removeEventListener("keydown", onKeyEvent);
		}
	}, [isDelete, isShowingUserManage]);

	function handleProfileMenuOpen(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(null);
	}

	function upload() {
		handleMenuClose ();
		openNewLink ("/upload");
	}

	function showUserManage() {
		handleMenuClose ();
		setShowingUserManage(true);
	}

	function onHomeClick() {
		handleMenuClose();
		if (props.noAccount) {
			redirects("/");
		}
		else {
			redirects("/map");
		}
	}

	function onBackClick() {
		handleMenuClose();
		props.history.goBack();
	}

	function hideDeleteDialog() {
		setDeleteDialog(false);
		setDeleteMessage("");
		setDeleteTitle("");
		setDeleteButtonName("");
		window.deleteCallback = null;
	}

	function deleteRun() {
		setDeleteDialog(false);
		setDeleteMessage("");
		setDeleteTitle("");
		setDeleteButtonName("");

		if (window.deleteCallback) {
			window.deleteCallback();
		}
	}

	function getDeleteDialog() {
        return (
            <Modal show={isDelete} animation={false} className="modal-delete-dialog">
                <Modal.Header>
					<div className='delete_dialog_title'>
						<Modal.Title>{deleteTitle}</Modal.Title>
						<div className='close_button' onClick={hideDeleteDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='delete_dialog_message'>
						{deleteMessage}
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained"
						color="secondary" onClick={hideDeleteDialog} className={classes.button}>
						Close
					</Button>
					<Button variant="contained"
						color="primary" onClick={deleteRun} className={classes.button}>
						{deleteButtonName}
					</Button>
                </Modal.Footer>
            </Modal>
        );
    }

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			className="profile-menu-style"
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{(props.userInfo && (props.userInfo.role === "owner" || props.userInfo.role === "admin") && !api.isSharing())?
			<MenuItem onClick={showUserManage}>Account</MenuItem>
			:null}
			{(api.canEditItem(props.userInfo))?
			<MenuItem onClick={upload}>Upload</MenuItem>
			:null}
			<MenuItem onClick={logout}>Logout</MenuItem>
		</Menu>
	);

	function redirects(path) {
		props.history.push(path);
	}

	function openNewLink(path) {
		window.open(path);
	}

	function logout() {
		handleMenuClose();
		localStorage.removeItem("userToken");
		localStorage.removeItem("checkTime");
		props.setUserInfo({
			"first_name" : "",
			"last_name" : "",
			point_cloud_density: 70,
			enable_annotation_popup: 1,
			measurement_units: "imperial",
			label_property: "default",
			pole_icon: 0,
			style_color: "#929292",
			measurement_snap_on: 1,
			profile_name: "profile1",
    		profile1_classification: "",
			profile2_classification: "",
			profile3_classification: "",
			annotation_type_list: "",
			annotation_status_list: "",
		});
		props.setDatasetList(new Map());
		props.selectProject({
			project : {
				id : -1
			}
		});
		redirects("/");
		window.location.reload();
	}

	function getUserName() {
		if (props.userInfo && props.userInfo.first_name) {
			return props.userInfo.first_name;
		}

		return "";
	}

	function hideUserManage() {
		setShowingUserManage(false);
	}

	return (
		<div className={classes.root}>
			<AppBar position="absolute" className={classes.appBar}>
				<Toolbar className={clsx(classes.noPadding, !props.drawerVisibility && classes.defaultPadding)}>
					{props.hasBack?
					<div className={clsx(classes.back)} onClick={onBackClick}>
						<img src="/back.png" alt="symbol" style={{height: "60%"}}/>
					</div>
					:null}
					<div className={clsx(classes.logo, !props.drawerVisibility && classes.toggleImage)} onClick={onHomeClick}>
						<img src="/symbol.png" alt="symbol" style={{height: "60%"}}/>
					</div>

					<div className={classes.grow} />

					<div id="search-box" className={!props.hasSearch? 'hidden' : undefined}>
					</div>
					{api.isSharing() || props.noAccount?null:
					<div className={classes.sectionDesktop}>
						<p className='header_username'>{getUserName()}</p>
						<IconButton
							edge="end"
							aria-label="Account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							color="inherit"
							className="header-menu-icon-width"
							onClick={handleProfileMenuOpen}
						>
							<AccountCircle />
						</IconButton>
					</div>
					}
				</Toolbar>
			</AppBar>
			{renderMenu}
			{(props.userInfo && (props.userInfo.role === "owner" || props.userInfo.role === "admin"))?
			<Modal show={isShowingUserManage} animation={false} className="modal-user-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideUserManage}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserManagement 
						onClose = {hideUserManage}
						onDeleteDialog={(title, message, callback, buttonName) => {
							setDeleteTitle(title);
							setDeleteMessage(message);
							window.deleteCallback = callback;
							if (buttonName) {
								setDeleteButtonName(buttonName);
							}
							else {
								setDeleteButtonName("Delete");
							}
							setDeleteDialog(true);
						}}
					/>
                </Modal.Body>
            </Modal>
			:null}
			{getDeleteDialog()}
		</div>
	);
}

const mapStateToProps = state => ({
	drawerVisibility: state.global.drawerVisibility,
	userInfo: state.global.userInfo 
});

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
	connect(mapStateToProps, authActionCreators),
)(Header);